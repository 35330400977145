<template>
  <div id="parents-page">
    <AppContent
      class="parents-content"
      :isFluid="false"
      :isContentContainer="true">
      <div class="content-wrapper">
        <h1 class="text-center heading">
          JOIN THE HEALTHY HIP HOP MOVEMENT!
        </h1>
        <p class="text-center mb-0">
          Do you love hip-hop music, just not always the messaging behind
          the beat? Or, you love listening to hip-hop music, just not
          around your kids? Healthy Hip Hop is the answer to these
          problems! We provide kids music that parents can actually enjoy
          and a safe social media experience for children!
        </p>
      </div>
      
      <b-row align-v="center">
        <b-col cols="12" lg="5" xl="5">
          <b-img-lazy
            class="image"
            v-bind="mainProps"
            :src="img1"
            alt="Image 1"
          ></b-img-lazy>
        </b-col>
        <b-col cols="12" lg="7" xl="7">
          <div class="text">
            <h3>FAMILY</h3>
            <p>
              Healthy Hip Hop uses culturally relevant music and cutting-edge
              technology to create enjoyable experiences and
              connect families.
            </p>
          </div>
        </b-col>
      </b-row>
      
      <b-row align-v="center">
        <b-col cols="12" lg="7" xl="7" order="2" order-lg="1" order-xl="1">
          <div class="text">
            <h3>EMPOWERMENT</h3>
            <p>
              Our programs empower parents and educators with engaging resources
              needed to lead today’s kids into a brighter and healthier future.
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="5" xl="5" order="1" order-lg="2" order-xl="2">
          <b-img-lazy
            class="image"
            v-bind="mainProps"
            :src="img2"
            alt="Image 1"
          ></b-img-lazy>
        </b-col>
      </b-row>
      
      <b-row align-v="center">
        <b-col cols="12" lg="5" xl="5">
          <b-img-lazy
            class="image"
            v-bind="mainProps"
            :src="img3"
            alt="Image 1"
          ></b-img-lazy>
        </b-col>
        <b-col cols="12" lg="7" xl="7">
          <div class="text">
            <h3>CULTURE</h3>
            <p>
              Healthy Hip Hop celebrates and represents the diversity of the
              world we live in, providing authentic solutions and social
              interaction.
            </p>
          </div>
        </b-col>
      </b-row>
    </AppContent>

    <AppFooter />
  </div>
</template>

<script>
  export default {
    head : {
      title : {
        inner      : 'Parents',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        mainProps : {
          center     : true,
          fluidGrow  : true,
          blank      : true,
          blankColor : '#00000080',
          width      : 600,
          height     : 400,
          class      : 'my-5',
        },
        img1 : require('../../assets/images/family_dance.jpg'),
        img2 : require('../../assets/images/mom_daughter.jpg'),
        img3 : require('../../assets/images/dad_daughter.jpg'),
      }
    },
    components : {
      'AppContent' : () => import('@/components/layout/AppContent'),
      'AppFooter'  : () => import('@/components/layout/AppFooter'),
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #parents-page {
    background-image: $parents-bg;
    background-size: cover;
    background-position: center;
  }

  .heading {
    margin-bottom: 2.5rem;
  }

  h1 {
    -webkit-text-stroke: 2.5px #000 !important;
  }
  h3 {
    -webkit-text-stroke: 2px #000 !important;
  }

  p {
    color: #fff;
    margin-bottom: 0;
  }

  .image {
    padding: 0.5rem;
    background-image: $section2-bg !important;
    background-position: bottom;
    border-radius: 2rem;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h1 {
      font-size: 1.7em !important;
      -webkit-text-stroke: 1px #000 !important;
    }
    h3 {
      font-size: 1.55em !important;
      -webkit-text-stroke: 1px #000 !important;
    }
    p {
      font-size: 1.25em !important;
      text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000 !important;
      -webkit-text-stroke: 0.25px #fff !important;
    }
    .parents-content {
      padding: 2rem 0;
      .content-wrapper {
        padding-bottom: 0;
      }
    }
    .image {
      width: 90% !important;
    }
    .text {
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    h1 {
      font-size: 2.5em !important;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    h3 {
      font-size: 2em !important;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    p {
      font-size: 1.5em !important;
      text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000 !important;
      -webkit-text-stroke: 0.25px #fff !important;
    }
    .parents-content {
      padding: 4rem 0;
      .content-wrapper {
        padding-bottom: 0;
      }
    }
    .image {
      width: 90% !important;
    }
    .text {
      text-align: center;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    h1 {
      font-size: 2.75em !important;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    h3 {
      font-size: 2.4em !important;
      -webkit-text-stroke: 1.75px #000 !important;
    }
    p {
      font-size: 1.75em !important;
      text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000 !important;
      -webkit-text-stroke: 0.25px #fff !important;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    h1 {
      font-size: 3em !important;
      -webkit-text-stroke: 2px #000 !important;
    }
    h3 {
      font-size: 2.25em !important;
    }
    p {
      font-size: 1.5em !important;
    }
    .image {
      width: 100% !important;
    }
    .text {
      text-align: left;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    p {
      font-size: 1.6em !important;
      text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000 !important;
      -webkit-text-stroke: 0.25px #fff !important;
    }
  }

  @media only screen and (min-width: $special) {}

  @media only screen and (min-width: $xl2-min) {
    h1 {
      font-size: 3.2em !important;
      -webkit-text-stroke: 2.5px #000 !important;
    }
    h3 {
      -webkit-text-stroke: 2.25px #000 !important;
    }
    p {
      font-size: 1.75em !important;
      text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000 !important;
      -webkit-text-stroke: 0.5px #fff !important;
    }
    .parents-content {
      padding: 5.5rem 0;
      .content-wrapper {
        padding-bottom: 5rem;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>